"use client";

import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Icons } from "@src/components";
import CountdownSale from "@src/components/CountdownSale";
import { useLayoutContext } from "@src/layouts/provider";
const TitleFlashSale = () => {
  const {
    config
  } = useLayoutContext();
  const [countdownSeconds, setCountdownSeconds] = useState(0);
  useEffect(() => {
    const {
      flash_sale
    } = config || {};
    if (flash_sale && flash_sale.id) {
      const {
        from,
        to
      } = flash_sale || {};
      const toTime = new Date(to).getTime();
      const now = new Date().getTime();
      if (now < toTime) {
        setCountdownSeconds((toTime - now) / 1000);
      }
    }
  }, [config]);
  return <Stack flexDirection={{
    xs: "column",
    lg: "row"
  }} alignItems="center" justifyContent={{
    xs: "center",
    lg: "start"
  }} gap={{
    xs: 2,
    lg: 4
  }} my={2} data-sentry-element="Stack" data-sentry-component="TitleFlashSale" data-sentry-source-file="title-flash-sale.tsx">
      <Box sx={{
      scale: {
        xs: 0.8,
        lg: 1
      }
    }} data-sentry-element="Box" data-sentry-source-file="title-flash-sale.tsx">
        <Icons name="flash-sale" data-sentry-element="Icons" data-sentry-source-file="title-flash-sale.tsx" />
      </Box>
      {countdownSeconds > 0 && <Typography display={{
      xs: "block",
      lg: "none"
    }} marginTop="-10px">
          Kết thúc trong
        </Typography>}
      <CountdownSale remainSeconds={countdownSeconds} data-sentry-element="CountdownSale" data-sentry-source-file="title-flash-sale.tsx" />
    </Stack>;
};
export default TitleFlashSale;