"use client";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CancelButton, Toastify } from "@src/components";
import Icons from "@src/components/Icons";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import { formatNumberCurrency } from "@src/utils/common";
import HomeSectionContainer from "@src/sections/home/home-section-container";
import useMutation from "@src/hooks/use-mutation";
import { useState } from "react";
import useQuery from "@src/hooks/use-query";
import type { Voucher } from "@src/types/voucher";
const IconVoucher = {
  freeship: {
    title: "Vận chuyển",
    icon: "free-ship"
  },
  percentage: {
    title: "Discount",
    icon: "discount"
  },
  amount: {
    title: "Đơn hàng",
    icon: "voucher"
  }
};
const WrapperItem = styled(Stack)({
  border: "2px dashed #6D6D6D",
  borderRadius: "16px",
  padding: "16px",
  minWidth: "330px",
  direction: "row",
  gap: "16px",
  alignItems: "center"
});
const ItemSkeleton = () => {
  return <Box position="relative" sx={{
    paddingBottom: "32px"
  }} data-sentry-element="Box" data-sentry-component="ItemSkeleton" data-sentry-source-file="voucher.tsx">
      <WrapperItem data-sentry-element="WrapperItem" data-sentry-source-file="voucher.tsx">
        <Skeleton animation="wave" variant="circular" width={40} height={40} data-sentry-element="Skeleton" data-sentry-source-file="voucher.tsx" />
        <Box data-sentry-element="Box" data-sentry-source-file="voucher.tsx">
          <Skeleton variant="rectangular" width={200} height={20} data-sentry-element="Skeleton" data-sentry-source-file="voucher.tsx" />
          <Skeleton sx={{
          marginTop: 1
        }} variant="rectangular" width={200} height={40} data-sentry-element="Skeleton" data-sentry-source-file="voucher.tsx" />
        </Box>
      </WrapperItem>
    </Box>;
};
const VoucherItem = (props: {
  data: Voucher;
  loading: boolean;
  hasSaved: boolean;
  onAddVoucher: (voucher: Voucher) => void;
}) => {
  const {
    data: {
      name,
      min_order_value,
      max_discount_amount,
      num_of_days_left,
      discount_type,
      voucher_type,
      saved
    },
    loading,
    data,
    onAddVoucher
  } = props;
  const typeVoucher = voucher_type?.toLowerCase() === "freeship" ? "freeship" : discount_type;
  return <Box position="relative" data-sentry-element="Box" data-sentry-component="VoucherItem" data-sentry-source-file="voucher.tsx">
      <WrapperItem data-sentry-element="WrapperItem" data-sentry-source-file="voucher.tsx">
        <Icons name={IconVoucher[typeVoucher].icon} data-sentry-element="Icons" data-sentry-source-file="voucher.tsx" />
        <svg width="3" height="50" viewBox="0 0 3 50" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="voucher.tsx">
          <path d="M1.5 1V49" stroke="#696969" strokeWidth="1.5" strokeLinecap="round" strokeDasharray="4 4" data-sentry-element="path" data-sentry-source-file="voucher.tsx" />
        </svg>
        <Stack direction="column" spacing="2px" data-sentry-element="Stack" data-sentry-source-file="voucher.tsx">
          <Typography sx={{
          fontSize: 16,
          fontWeight: 600,
          color: "#FF5F67"
        }} className="truncate-2" data-sentry-element="Typography" data-sentry-source-file="voucher.tsx">
            {name}
          </Typography>
          <Stack alignItems="center" sx={{
          color: "#696969",
          fontSize: 12
        }} spacing={1} data-sentry-element="Stack" data-sentry-source-file="voucher.tsx">
            <Typography variant="body2" component="span" sx={{
            color: "#686868"
          }} data-sentry-element="Typography" data-sentry-source-file="voucher.tsx">
              {`Đơn hàng từ ${formatNumberCurrency(Math.floor(min_order_value / 1000))}k`}
            </Typography>
            <span> | </span>
            <Typography variant="body2" component="span" sx={{
            color: "#686868"
          }} data-sentry-element="Typography" data-sentry-source-file="voucher.tsx">
              {`Giảm tối đa ${formatNumberCurrency(max_discount_amount)}k`}
            </Typography>
          </Stack>
          <Box data-sentry-element="Box" data-sentry-source-file="voucher.tsx">
            <Typography variant="body2" component="span" color="#686868" data-sentry-element="Typography" data-sentry-source-file="voucher.tsx">
              {`Còn lại: ${num_of_days_left} ngày`}
            </Typography>
          </Box>
        </Stack>
      </WrapperItem>
      <Stack width="100%" justifyContent="flex-end" data-sentry-element="Stack" data-sentry-source-file="voucher.tsx">
        {saved ? <CancelButton sx={{
        margin: "8px 0px",
        border: "1.5px solid #ECECEC !important",
        padding: "8px 12px",
        fontSize: "12px !important",
        height: "30px !important",
        minHeight: "30px !important",
        borderRadius: "32px !important",
        textTransform: "none",
        pointerEvent: "none",
        cursor: "not-allowed",
        color: "#D0D0D0 !important"
      }} disabled label="Đã lưu" /> : <CancelButton sx={{
        margin: "8px 0px",
        border: "1.5px solid #262626 !important",
        color: "#262626 !important",
        padding: "8px 12px",
        fontSize: "12px !important",
        height: "30px !important",
        minHeight: "30px !important",
        borderRadius: "32px !important",
        textTransform: "none"
      }} loading={loading} label="Lưu ngay" onClick={() => onAddVoucher(data)} />}
      </Stack>
    </Box>;
};
const VoucherComponent = ({
  data = []
}) => {
  const {
    mutation,
    loading: isLoadingSave
  } = useMutation();
  const [voucherSelected, setVoucherSelected] = useState(null);
  const handleAddVoucher = async (voucher: Voucher) => {
    if (voucher?.id) {
      setVoucherSelected(voucher);
      await mutation({
        url: `/auth/vouchers/${voucher.id}/save`,
        method: "POST",
        onSuccess: res => {
          if (res) {
            Toastify.success(res?.message || "Đã lưu voucher");
            refetch();
            setVoucherSelected(null);
          }
        },
        onFailed: () => {
          setVoucherSelected(null);
        }
      });
    }
  };
  if (!data || data.length === 0) {
    return null;
  }
  return <HomeSectionContainer data-sentry-element="HomeSectionContainer" data-sentry-component="VoucherComponent" data-sentry-source-file="voucher.tsx">
      <Typography variant="h3" component="h3" sx={{
      fontSize: {
        xs: "16px",
        md: "24px"
      },
      fontWeight: 700
    }} data-sentry-element="Typography" data-sentry-source-file="voucher.tsx">
        The C.I.U Voucher
      </Typography>
      <Stack mt={4} spacing={2} overflow="auto" data-sentry-element="Stack" data-sentry-source-file="voucher.tsx">
        {data?.length > 0 && data.map((item: Voucher) => <VoucherItem key={item.code} data={item} loading={isLoadingSave && item.id === voucherSelected?.id} onAddVoucher={handleAddVoucher} />)}
      </Stack>
    </HomeSectionContainer>;
};
export default VoucherComponent;