"use client";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NextImage } from "@src/components";
import useQuery from "@src/hooks/use-query";
import HomeSectionContainer from "@src/sections/home/home-section-container";
import CarouselProductSale from "./carousel-product-sale";
import TitleFlashSale from "./title-flash-sale";
import { useLayoutContext } from "@src/layouts/provider";
const FlashSale = () => {
  const {
    config
  } = useLayoutContext();
  const {
    data,
    loading
  } = useQuery({
    url: `/products/paginate?pageSize=20&type=flash_sale`
  });
  if (!config?.flash_sale?.isAvailable) {
    return null;
  }
  const renderContent = () => {
    if (loading) {
      return null;
    }
    if (data && data.length > 0) {
      return <CarouselProductSale data={data} />;
    }
    if (data && data.length === 0) {
      return <Stack flexDirection="column" pb={6} spacing={2} overflow="auto" alignItems="center" gap="60px">
          <Box sx={{
          width: "auto",
          height: "280px",
          margin: "0px auto"
        }}>
            <NextImage sx={{
            width: "auto",
            height: "100%",
            "& img": {
              position: "relative !important",
              width: "auto !important",
              objectFit: "cover"
            }
          }} src="/assets/images/empty-sale.png" />
          </Box>
          <Typography variant="body1" sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "28px",
          lineHeight: "36px"
        }}>
            Chương trình Flash Sale sắp diễn ra!
          </Typography>
        </Stack>;
    }
    return null;
  };
  return <HomeSectionContainer data-sentry-element="HomeSectionContainer" data-sentry-component="FlashSale" data-sentry-source-file="index.tsx">
      <TitleFlashSale data-sentry-element="TitleFlashSale" data-sentry-source-file="index.tsx" />
      {renderContent()}
    </HomeSectionContainer>;
};
export default FlashSale;